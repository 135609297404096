const loaderConfig = require('./config.json');

// Add fixed element to DOM to warn if any overrides are present
const addOverrideWarning = (message, consoleMsg) => {
  console.warn("Override detected: '" + consoleMsg);

  const callback = () => {
    const warningsContainerId = 'sn-session-override-warnings';
    let warningsContainer = document.getElementById(warningsContainerId);
    if (!warningsContainer) {
      warningsContainer = document.createElement('div');
      warningsContainer.id = warningsContainerId;
      warningsContainer.setAttribute('style', 'background: darkred; position: fixed; bottom: 0; left: 0; right: 0; z-index: 1000000; padding: 0.25em; color: white; font-family: monospace; font-size: small;');
      warningsContainer.innerHTML = '(!) Session overrides:';
      warningsContainer.addEventListener('mouseover', () => (warningsContainer.style.opacity = '0.20'));
      warningsContainer.addEventListener('mouseout', () => (warningsContainer.style.opacity = '1'));
      document.body.appendChild(warningsContainer);
    }
    warningsContainer.innerHTML += message;
  };

  const handleReadyState = () => {
    document.removeEventListener('DOMContentLoaded', handleReadyState, false);
    callback();
  };

  const readyState = document.readyState;
  if (readyState === 'interactive' || readyState === 'complete') {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', handleReadyState, false);
  }
};

/**
 * Initialize configuration
 * Choose config based on loader configuration, i.e. base or experiments
 * Override configuration with optional debug configurations
 *
 * @returns {boolean} true if loader should continue, false if loader should abort
 */
module.exports = {
  init: () => {
    try {
      const snConfig = (window._snigelConfig ||= {});
      const expStorageItemName = '_sn_exp';
      const expStorage = window.localStorage;

      // Session override settings
      const overrideConfig = JSON.parse(window.sessionStorage.getItem('snSessionOverrides'));

      // The overriding loader is the loader set in the override and created by the original loader
      const isTheOverridingLoader = overrideConfig && overrideConfig.loaderUrl && overrideConfig.loaderUrl === document.currentScript.src;

      if (!isTheOverridingLoader || overrideConfig.loaderSettingsOverride) {
        // Copy loader config to _snigelConfig (skip experiments)
        for (const property in loaderConfig) {
          if (property != 'exp') {
            snConfig[property] = loaderConfig[property];
          }
        }

        // Choose experiment
        if (loaderConfig.exp) {
          // Read and parse previous experiment choice
          let storedCfgVer, storedExpId;
          [storedCfgVer, storedExpId] = (expStorage.getItem(expStorageItemName) || '').split(';');
          if (storedCfgVer != loaderConfig.cfgVer) {
            storedCfgVer = loaderConfig.cfgVer;
            storedExpId = undefined;
          }

          // Select the right version, by using the stored experiment or the experiment frequency
          if (
            !loaderConfig.exp.some(exp => {
              if (storedExpId == exp.id || (storedExpId == undefined && Math.random() < exp.freq)) {
                // Store selected experiment id
                storedExpId = exp.id;

                // Append experiment id as query string to adengine request
                const expQueryString = 'exp=' + storedExpId;
                const adnginQueryString = exp.settings.adngin.queryParameters;
                exp.settings.adngin.queryParameters = adnginQueryString ? adnginQueryString + '&' + expQueryString : expQueryString;

                // Use experiment settings as active loader settings
                snConfig.settings = exp.settings;
                return true;
              }
              return false;
            })
          ) {
            storedExpId = 0;
          }

          // Remember choice
          expStorage.setItem(expStorageItemName, storedCfgVer + ';' + storedExpId);
        } else {
          // Delete local storage item when there is no active experiment
          expStorage.removeItem(expStorageItemName);
        }
      }

      if (overrideConfig) {
        // Loader override, add a new loader and abort this one
        const loaderUrl = overrideConfig.loaderUrl;
        if (loaderUrl) {
          if (!isTheOverridingLoader) {
            addOverrideWarning(' (Loader overriden) ', "Loader'.");
            const scriptTag = document.createElement('script');
            scriptTag.src = loaderUrl;
            scriptTag.async = true;
            document.head.appendChild(scriptTag);

            // abort script
            return false;
          }
        }

        const products = overrideConfig.products;
        const adEngineCoreConfig = overrideConfig.adEngineCoreConfig;

        if (products && typeof products === 'object' && !Array.isArray(products)) {
          Object.entries(products).forEach(([product, productOverride]) => {
            const productSettings = snConfig.settings[product];
            const message = productOverride.message || '';
            productSettings.url = productOverride.url;
            productSettings.queryParameters = productOverride.queryParameters;
            addOverrideWarning(' (' + product + ', ' + message + ')', product + "': " + message);
          });
        }
        if (adEngineCoreConfig) {
          snConfig.adEngineCoreConfigOverride = adEngineCoreConfig;
          addOverrideWarning(' (AdEngine coreConfig overriden) ', "AdEngine coreConfig'.");
        }
      }
    } catch (e) {}
    return true;
  },

  get: () => window._snigelConfig || {}
};

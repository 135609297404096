(function (win, doc, coreName, gppapi, tcfapi, tcfca, uspapi, storage) {
  // Get core name from global snigel configuration if available, otherwise use default
  const snigelConfig = win._snigelConfig;
  if (snigelConfig) {
    try {
      coreName = snigelConfig.settings.adconsent.objName;
    } catch (e) {
      /* empty */
    }
  }

  // Constants
  const UNDEFINED = undefined;
  const cmpVersion = 9999;
  const cmpId = 229;
  const globalapi = '__' + coreName;
  const stub = 'stub';
  const loaded = 'loaded';
  const visible = 'visible';
  const hidden = 'hidden';
  const disabled = 'disabled';
  const cmpGUIShow = 'cmpGUIShow';
  const cmpGUISubmit = 'cmpGUISubmit';
  const cmpConsentAvailable = 'cmpConsentAvailable';
  const pwUserStatusStorageName = 'pwuserstatus';
  let cmpHasFullConsent = undefined;

  const availableApis = {
    'tcfeuv2': { z: 1, v: 2, sid: 2, api: tcfapi, f: tcfapi, s: 'getTCData', c: 'euconsent-v2', n: 'GDPR' },
    'uspv1': { z: 2, v: 1, sid: 6, api: uspapi, f: uspapi, s: 'getUSPData', c: 'usprivacy', n: 'CCPA' },
    'tcfcav1': { z: 3, v: 2, sid: 5, api: coreName + '.pipeda', f: tcfca, s: 'getTCData', c: 'caconsent', n: 'PIPEDA' },
    'usnat': { z: 4, v: 1, sid: 7, api: coreName + '.usnat', f: '__usnat', s: 'getUSData', c: 'usconsent', n: 'USNATIONAL' },
    'usca': { z: 5, v: 1, sid: 8, api: coreName + '.usnat', f: '__usnat', s: 'getUSData', c: 'uscaconsent', n: 'US-CALIFORNIA' },
    'usco': { z: 6, v: 1, sid: 10, api: coreName + '.usnat', f: '__usnat', s: 'getUSData', c: 'uscoconsent', n: 'US-COLORADO' },
    'usct': { z: 7, v: 1, sid: 12, api: coreName + '.usnat', f: '__usnat', s: 'getUSData', c: 'usctconsent', n: 'US-CONNECTICUT' },
    'usva': { z: 8, v: 1, sid: 9, api: coreName + '.usnat', f: '__usnat', s: 'getUSData', c: 'usvaconsent', n: 'US-VIRGINIA' },
    'usut': { z: 9, v: 1, sid: 11, api: coreName + '.usnat', f: '__usnat', s: 'getUSData', c: 'usutconsent', n: 'US-UTAH' }
  };
  const gppFibonacci = [1, 2];
  const gppPingReturn = {
    gppVersion: '1.1',
    supportedAPIs: Object.keys(availableApis).map(key => {
      return availableApis[key].sid + ':' + key;
    }),
    cmpStatus: stub,
    cmpDisplayStatus: disabled, // default when no sections apply
    cmpId: cmpId
  };

  function isFunction(f) {
    return typeof f === 'function';
  }
  function isString(s) {
    return typeof s === 'string';
  }

  // try setting storage to localStorage
  try {
    storage = localStorage;
  } catch (e) {
    /* empty */
  }

  // performance.now polyfill
  const perf = win.performance;
  const perfNowMs =
    perf && perf.now
      ? function () {
          return Math.floor(perf.now());
        }
      : function () {
          return 0;
        };

  // CustomEvent() polyfill
  (function () {
    if (isFunction(win.CustomEvent)) return false;
    function CustomEvent(event, params) {
      params = params || { bubbles: false, cancelable: false, detail: UNDEFINED };
      const evt = doc.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
    }
    CustomEvent.prototype = win.Event.prototype;
    win.CustomEvent = CustomEvent;
  })();

  function sendToArgus(args) {
    if (window.argus) {
      const logObject = args.length === 1 && args[0] instanceof Error ? { stack: args[0].stack } : { log: args, stack: new Error().stack };
      window.argus.cmd.push(function () {
        window.argus.queueError('adconsent', logObject);
      });
    }
  }

  try {
    // Helper to parse query strings and returns a map of query strings
    function parseQS(query) {
      return !query
        ? {}
        : query
            .replace(/^\?/, '')
            .split('&')
            .reduce(function (acc, criteria) {
              let split = criteria.split('='),
                k = split[0],
                v = split.length > 1 ? split[1] : UNDEFINED;
              if (/\[\]$/.test(k)) {
                k = k.replace('[]', '');
                acc[k] = acc[k] || [];
                acc[k].push(v);
              } else {
                acc[k] = v || '';
              }
              return acc;
            }, {});
    }

    // Helper to decorate log statements
    function decorateLog(args, prefix, color) {
      args = [].slice.call(args);
      prefix && args.unshift(prefix);
      args.unshift('display: inline-block; color: #fff; background: ' + color + '; padding: 1px 4px; border-radius: 3px;');
      args.unshift('%cAdConsent');
      return args;
    }

    // Generic log preparation function
    function spitLog(logType, loggingLevel, args, color) {
      if (win.console[logType] && coreLog.level >= loggingLevel) {
        console[logType].apply(console, decorateLog(args, logType.toUpperCase() + ':', color));
      }
    }

    // Consent region creator
    function createRegions() {
      const consent = {
        regions: { 0: 'NONE' },
        region: UNDEFINED,
        api: {}
      };
      for (let api in availableApis) {
        const apiData = availableApis[api];
        consent.regions[apiData.z] = apiData.n;
        consent.api[api] = {
          region: apiData.z,
          loaded: false,
          applies: UNDEFINED,
          version: apiData.v,
          status: stub
        };
      }
      return consent;
    }

    function apiCreator(api) {
      return api === gppapi
        ? function (command, parameter, callback) {
            win[api](command, callback, parameter, UNDEFINED);
          }
        : function (command, parameter, callback) {
            win[api](command, UNDEFINED, callback, parameter);
          };
    }

    // Define core object
    const coreObj = apiCreator(globalapi);

    // utility functions
    coreObj.utils = {
      getStorageItem: name => {
        if (storage) {
          const storageName = '_sn_' + name;
          try {
            const storageKeys = storage.getItem(storageName).split('~');
            if (storageKeys.length === 2 && parseInt(storageKeys[0]) >= Date.now()) {
              return storageKeys[1];
            } else {
              storage.removeItem(storageName);
            }
          } catch (error) {
            storage.removeItem(storageName);
          }
        }
      },

      setStorageItem: (name, value, maxAgeSeconds) => {
        storage && storage.setItem('_sn_' + name, Date.now() + maxAgeSeconds * 1000 + '~' + value);
      },

      deleteStorageItem: name => {
        storage && storage.removeItem('_sn_' + name);
      }
    };

    //global functions
    coreObj.functions = {};

    //core constants
    coreObj.constants = {};
    coreObj.constants.pwUserStatusStorageName = pwUserStatusStorageName;

    // define consent law specific interface proxies and internal vars/functions
    // gdpr
    coreObj.gdpr = apiCreator(tcfapi);
    const coreGDPR = coreObj.gdpr;
    coreGDPR.listenerId = 1;
    coreGDPR.tcfListeners = [];
    coreGDPR.addEventListener = function (version, callback, getTCDataFunc) {
      if (checkVersion(coreConsentApiTcf, version, callback)) {
        coreLogDebug('Adding event listener ' + coreGDPR.listenerId, callback);
        const currentHandler = { id: coreGDPR.listenerId++, callback: callback ? callback : function () {} };
        coreGDPR.tcfListeners.push(currentHandler);
        getTCDataFunc(null, currentHandler.id, currentHandler.callback);
      }
    };
    coreGDPR.removeEventListener = function (_api, _command, version, callback, parameter) {
      if (checkVersion(coreConsentApiTcf, version, callback)) {
        coreLogDebug('Removing event listener ' + parameter);
        for (let i = 0; i < coreGDPR.tcfListeners.length; i++) {
          if (coreGDPR.tcfListeners[i].id == parameter) {
            coreGDPR.tcfListeners.splice(i, 1);
            sendCallback(callback, true);
            return;
          }
        }
        coreLogWarn("Couldn't find listener id " + parameter + '.');
        sendCallback(callback, false);
      }
    };
    coreGDPR.getTCData = function (_api, _command, version, callback) {
      if (checkVersion(coreConsentApiTcf, version, callback)) {
        return getTCDataForStub(null, 0, callback);
      }
    };
    coreGDPR.processListeners = function (getTCDataFunc) {
      const tcfListeners = coreGDPR.tcfListeners.slice();
      for (let i = 0; i < tcfListeners.length; i++) {
        getTCDataFunc(null, tcfListeners[i].id, tcfListeners[i].callback);
      }
    };
    // ccpa
    coreObj.ccpa = apiCreator(uspapi);

    // gpp
    coreObj.gpp = apiCreator(gppapi);
    const coreGPP = coreObj.gpp;
    coreGPP.eventListeners = { id: 0 };
    coreGPP.fibonacciEncode = function (value) {
      const indexes = gppFibonacciEncArray(value);
      let res = '';
      if (indexes.length) {
        let idx = 0;
        const length = indexes[0];
        let idxVal = indexes[idx];
        res = '1';
        let bit;

        for (let i = length; i > 0; i--) {
          bit = '0';
          if (i === idxVal) {
            bit = '1';
            if (++idx < indexes.length) {
              idxVal = indexes[idx];
            }
          }
          res = bit + res;
        }
      }
      return res;
    };
    coreGPP.fibonacciDecode = function (value) {
      let res = 0;
      if (value.length > 1) {
        for (let i = 0; i < value.length - 1; i++) {
          if (i >= gppFibonacci.length) {
            gppFibonacci.push(gppFibonacci[i - 1] + gppFibonacci[i - 2]);
          }
          if (value.charAt(i) === '1') {
            res += gppFibonacci[i];
          }
        }
      }
      return res;
    };

    // Set core object configuration
    coreObj.version = cmpVersion;
    coreObj.cmpId = cmpId;
    // Configuration
    const defaultConfig = {
      apiBaseUrl: 'https://dev.cdn.snigelweb.com/adconsent/master',
      publisherCC: 'US',
      storage: 0 // cookie
    };
    coreObj.cfg = snigelConfig && snigelConfig.adConsentConfigOverrides ? snigelConfig.adConsentConfigOverrides : defaultConfig;
    const coreCfg = coreObj.cfg;

    // Logging interface
    coreObj.log = {
      levels: { 'off': 0, 'error': 1, 'warning': 2, 'info': 3, 'debug': 4 },
      level: 4,
      error: function () {
        sendToArgus(arguments);
        spitLog('error', 1, arguments, '#ff0000');
      },
      warn: function () {
        spitLog('warn', 2, arguments, '#ffe600');
      },
      info: function () {
        spitLog('info', 3, arguments, '#3b88a3');
      },
      debug: function () {
        spitLog('debug', 4, arguments, '#808080');
      }
    };
    const coreLog = coreObj.log;
    const coreLogDebug = coreLog.debug;
    const coreLogInfo = coreLog.info;
    const coreLogWarn = coreLog.warn;
    const coreLogError = coreLog.error;

    // api status for various consent regions
    coreObj.consent = createRegions();
    const coreConsent = coreObj.consent;
    const coreConsentRegions = coreConsent.regions;
    const coreConsentApi = coreConsent.api;
    const coreConsentApiTcf = coreConsentApi.tcfeuv2;
    const coreConsentApiUsp = coreConsentApi.uspv1;
    // const coreConsentApiUsnat = coreConsentApi.usnat;

    // Analytics interface for Google Analytics events
    coreObj.analytics = {
      enabled: false, // analytics enabled/disabled
      callback: UNDEFINED, // analytics callback function. if not defined, automatically detect ga/gtag

      // Send a Google Analytics event with the current privacy region used as eventAction
      send: function (eventLabel) {
        coreAnalytics.sendEvent(coreConsentRegions[coreConsent.region], eventLabel, perfNowMs());
      },

      // Send a Google Analytics event with the given eventAction and eventLabel
      sendEvent: function (eventAction, eventLabel, eventValue) {
        coreLogInfo('Sending analytics event action' + (coreAnalytics.enabled ? '' : ' disabled') + ': ' + eventAction + ', label: ' + eventLabel + ', value: ' + eventValue);
        if (coreAnalytics.enabled) {
          const sendFunc =
            coreAnalytics.callback ||
            function (event) {
              if (win.gtag) {
                gtag('event', event.action, { event_category: event.category, event_label: event.label, event_value: event.value });
              } else if (win.ga) {
                ga('send', { hitType: 'event', eventCategory: event.category, eventAction: event.action, eventLabel: event.label, eventValue: event.value, nonInteraction: event.nonInteraction });
              } else {
                coreLogWarn('Unable to find Google Analytics module (gtag or ga).');
              }
            };
          sendFunc({
            category: 'AdConsent',
            action: eventAction,
            label: eventLabel || eventAction,
            value: eventValue || 0,
            nonInteraction: true
          });
        }
      }
    };
    const coreAnalytics = coreObj.analytics;
    const coreAnalyticsSend = coreAnalytics.send;

    // Event interface for legacy window CustomEvents
    coreObj.event = {
      fired: {}, // object registering fired events

      // Send a CustomEvent window event
      dispatchCustomEvent: function (eventName, eventDetail, fireOnlyOnce) {
        // Only fire event if not yet fired
        if (!fireOnlyOnce || !coreEventFired[eventName]) {
          // Dispatch custom event
          coreEventFired[eventName] = true;
          coreLogDebug('Emitting custom event ' + eventName + ' with details: ', eventDetail);
          doc.dispatchEvent(new CustomEvent(eventName, eventDetail));
        }
      },

      // Send consent event
      dispatchCustomEventConsent: function (publisherConsent, vendorsConsent) {
        // Send analytics event
        const consentMap = {
          0: 'N/A',
          1: 'NoConsent',
          2: 'PartialConsent',
          3: 'FullConsent'
        };
        // Send vendor consent
        coreAnalyticsSend(consentMap[vendorsConsent]);
        // Send publisher consent, only if in EEA and publisher consent is available
        if ((coreConsent.region == availableApis.tcfeuv2.z || coreConsent.region == availableApis.tcfcav1.z) && publisherConsent != 0) {
          coreAnalyticsSend('Publisher' + consentMap[publisherConsent]);
        }
        const globalConsent = resolveGlobalConsent(publisherConsent, vendorsConsent);
        cmpHasFullConsent = globalConsent == 3;

        // Send window custom event
        coreEvent.dispatchCustomEvent(cmpConsentAvailable, {
          detail: {
            consentSummary: {
              mapping: {
                0: 'not available',
                1: 'no consent',
                2: 'partial consent',
                3: 'full consent'
              },
              publisherConsent: publisherConsent,
              vendorsConsent: vendorsConsent,
              globalConsent: globalConsent,
              gdprApplies: coreConsentApiTcf.applies,
              uspApplies: coreConsentApiUsp.applies,
              currentAPI: gppPingReturn.currentAPI
            }
          }
        });
      }
    };
    const coreEvent = coreObj.event;
    const coreEventFired = coreEvent.fired;

    // Parse query string and initialize log level
    const queryStrings = parseQS(win.location.search);
    const logLevel = (queryStrings['sn_debug'] == 'true' ? 'debug' : null) || (queryStrings['adconsent_debug'] == 'true' ? 'debug' : null) || queryStrings['adconsent_log'];
    coreLog.level = coreLog.levels[logLevel] || coreLog.level;

    // Avoid double instantiation
    if (win[coreName]) {
      coreLogError('Stub is tried to load again!');
      return;
    }
    // Check if a cmp is already registered
    if (win[tcfapi] || win[uspapi] || win[gppapi]) {
      coreLogWarn('A cmp is already registered in the system. AdConsent is stopping.');
      return;
    }
    // Set core object
    win[coreName] = coreObj;

    ///////////////////////////////////////////////
    // Function definitions
    ///////////////////////////////////////////////

    function checkVersion(api, version, callback) {
      if (!version || version === api.version) {
        return true;
      }
      sendCallback(callback, null, false);
      coreLogDebug('Wrong framework version detected: ' + version);
      return false;
    }

    // pre-defined TC-String if gdpr does not apply
    function getTCDataForStub(_idArray, listenerId, callback) {
      const applies = coreConsentApiTcf.applies ? UNDEFINED : coreConsentApiTcf.applies;
      const TCData = {
        tcString: UNDEFINED,
        tcfPolicyVersion: 5,
        cmpId: cmpId,
        cmpVersion: cmpVersion,
        gdprApplies: applies,
        eventStatus: applies === false ? 'tcloaded' : UNDEFINED,
        cmpStatus: coreConsentApiTcf.status,
        listenerId: listenerId,
        isServiceSpecific: true,
        useNonStandardStacks: false,
        publisherCC: coreCfg.publisherCC,
        purposeOneTreatment: false
      };
      coreLogDebug('Sending TCData structure:', TCData, callback);
      sendCallback(callback, TCData, true);
      return TCData;
    }

    // Add command to stub queue to be processed by the consent specific implementation when it is loaded
    function addToStubQueue(api, command, version, callback, parameter) {
      api.queue.push({
        command: command,
        version: version,
        parameter: parameter,
        callback: callback
      });
    }

    function getApiStruct(apiName) {
      const apiPath = Array.isArray(apiName) ? apiName : (apiName || '').split('.');
      const internalApi = apiPath.length > 1;
      const apiEndIdx = apiPath.length - 1;
      const apiEnd = apiPath[apiEndIdx];
      let root = win;
      for (let i = 0; i < apiEndIdx; i++) {
        root = root[apiPath[i]];
        if (!root) break;
      }
      return { r: root, e: apiEnd, apiParts: apiPath, api: root ? root[apiEnd] : UNDEFINED, internal: internalApi };
    }

    // Initialize the given api
    // Create iframe and locator and register message handler for apis (__adconsent, __tcfapi, __uspapi)
    function handleAPI(apiName, specificFuncs) {
      const apiMsgHandler = function (event) {
        const data = event.data;
        const msgIsString = isString(data);
        try {
          const jsonData = (msgIsString ? JSON.parse(data) : data)[apiName + 'Call'];
          if (jsonData) {
            const msgCallback = function (retValue, success) {
              try {
                // Check if the event source still exists. It might get destroyed by SSR by destroying the source iframe. See https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage
                if (event && event.source && event.source.postMessage) {
                  const returnMsg = {};
                  returnMsg[apiName + 'Return'] = {
                    'returnValue': retValue,
                    'success': success === UNDEFINED ? true : success,
                    'callId': jsonData.callId
                  };
                  event.source.postMessage(msgIsString ? JSON.stringify(returnMsg) : returnMsg, '*');
                }
              } catch (ignore) {
                /* empty */
              }
            };
            if (apiName === gppapi) {
              win[apiName](jsonData.command, msgCallback, jsonData.parameter, jsonData.version);
            } else {
              win[apiName](jsonData.command, jsonData.version, msgCallback, jsonData.parameter);
            }
          }
        } catch (ignore) {
          /* empty */
        }
      };

      const apiStruct = getApiStruct(apiName);
      if (!apiStruct.internal) {
        const addFrame = function (locator) {
          if (!win.frames[locator]) {
            const body = doc.body;
            if (body) {
              const iframe = doc.createElement('iframe');
              iframe.style.display = 'none';
              iframe.name = locator;
              body.appendChild(iframe);
            } else {
              setTimeout(addFrame, 5, locator);
            }
          }
        };
        addFrame(apiName + 'Locator');
      }

      if (apiStruct.r && !isFunction(apiStruct.api)) {
        // check if we already have a non-stub implementation
        const api = function (command, p1, p2, p3) {
          const cmpApiObj = getApiStruct(apiStruct.apiParts).api;
          if (cmpApiObj.queue) {
            // check if we're still using the stub
            let found = false;
            for (let prop in specificFuncs) {
              if (command === prop) {
                found = true;
                const comm = specificFuncs[prop];
                return comm(cmpApiObj, command, p1, p2, p3);
              }
            }
            if (!found) {
              if (apiName === gppapi) {
                const specapiData = (command || 'none').split('.');
                const specApi = specapiData.length === 2 ? availableApis[specapiData[0]] : UNDEFINED;
                if (specApi) {
                  const specApiStruct = getApiStruct(specApi.api);
                  if (specApiStruct.internal) {
                    specApiStruct.api(specapiData[1], p2, p1);
                  } else {
                    specApiStruct.api(specapiData[1], specApi.v, p1, p2);
                  }
                } else {
                  coreLogError('Unknown ' + apiName + "() API call '" + command + "'");
                }
              } else if (apiStruct.internal) {
                addToStubQueue(cmpApiObj, command, p3, p2, p1);
              } else {
                addToStubQueue(cmpApiObj, command, p1, p2, p3);
              }
            }
          } else {
            // to recover from wrong stub referencing from SSP's
            return cmpApiObj(command, p1, p2, p3);
          }
        };

        apiStruct.r[apiStruct.e] = api;
        apiStruct.r[apiStruct.e].queue = [];
        if (!apiStruct.internal) {
          if (win.addEventListener) {
            win.addEventListener('message', apiMsgHandler, false);
          } else {
            win.attachEvent('onmessage', apiMsgHandler);
          }
        }
      }
    }

    // Helper function to send a value to a callback function
    function sendCallback(callback, value, success) {
      if (isFunction(callback)) {
        setTimeout(function () {
          callback(value, success);
        }, 0);
      }
    }

    // standalone functions
    function getLocal(local, callback) {
      const storageBase = 'snconsent_geo';
      const storageValue = storageBase;
      const storageExpiry = storageBase + '_exp';

      if (!local || !local.country) {
        local = null;
        if (storage) {
          const localVal = storage.getItem(storageValue);
          if (localVal) {
            const validity = storage.getItem(storageExpiry);
            if (validity) {
              try {
                if (parseInt(validity) >= new Date().getTime()) {
                  local = JSON.parse(win.atob(localVal));
                }
              } catch (error) {
                /* empty */
              }
            }
          }
        }
      }
      if (!local) {
        const oReq = new XMLHttpRequest();
        oReq.open('GET', 'https://pro.ip-api.com/json/?fields=24582&key=33arzTfj1gigDqW');
        oReq.timeout = 5000;
        oReq.onload = function () {
          const resp = oReq.responseText.toLowerCase();
          let apiLocal = JSON.parse(resp);
          apiLocal = { country: apiLocal.countrycode, region: apiLocal.region };

          if (storage) {
            storage.setItem(storageValue, win.btoa(JSON.stringify(apiLocal)));
            storage.setItem(storageExpiry, new Date().getTime() + 3600000); // expires in 1 hour
          }
          callback(apiLocal);
        };
        oReq.onerror = oReq.ontimeout = function () {
          callback(null);
        };
        oReq.send();
      } else {
        callback(local);
      }
    }

    function resolveGlobalConsent(publisherConsent, vendorsConsent) {
      if ((!publisherConsent || publisherConsent === 3) && vendorsConsent === 3) {
        return 3;
      } else if (!publisherConsent && !vendorsConsent) {
        return 0;
      } else if ((!publisherConsent || publisherConsent === 1) && vendorsConsent === 1) {
        return 1;
      } else {
        return 2;
      }
    }
    coreObj.resolveGlobalConsent = resolveGlobalConsent;

    // Start the stub
    let started = false;
    function apiStart(_cmpApiObj, _command, _version, _callback, parameter) {
      if (coreConsent.region === UNDEFINED) {
        getLocal(parameter, function (local) {
          if (local) {
            coreCfg.country = local.country ? local.country.toLowerCase() : null;
            coreCfg.region = (local.region || '').toLowerCase();
          }

          if (coreObj.utils.getStorageItem(pwUserStatusStorageName)) {
            //if the paywall cookie exists we load the TCFAPI where the pw is implemented
            apiSetConsentRegion(UNDEFINED, UNDEFINED, 1, UNDEFINED, availableApis.tcfeuv2.z);
          } else if (coreCfg.country === 'us') {
            switch (coreCfg.region) {
              case 'ca':
                apiSetConsentRegion(UNDEFINED, UNDEFINED, 1, UNDEFINED, availableApis.uspv1.z);
                break;
              // case 'co':
              // case 'ct':
              // case 'ut':
              // case 'va':
              //   apiSetConsentRegion(UNDEFINED, UNDEFINED, 1, UNDEFINED, availableApis.usnat.z);
              //   break;
              default:
                apiSetConsentRegion(UNDEFINED, UNDEFINED, 1, UNDEFINED, 0);
                break;
            }
            //apiSetConsentRegion(UNDEFINED, UNDEFINED, 1, UNDEFINED, availableApis.uspv1.z);
            // if (coreCfg.region === 'california' || coreCfg.region === 'ca') {
            //   apiSetConsentRegion(UNDEFINED, UNDEFINED, 1, UNDEFINED, availableApis.usca.z);
            //   coreConsentApiUsnat.section = 'usca';
            // } else if (coreCfg.region === 'colorado' || coreCfg.region === 'co') {
            //   apiSetConsentRegion(UNDEFINED, UNDEFINED, 1, UNDEFINED, availableApis.usco.z);
            //   coreConsentApiUsnat.section = 'usco';
            // } else if (coreCfg.region === 'connecticut' || coreCfg.region === 'ct') {
            //   apiSetConsentRegion(UNDEFINED, UNDEFINED, 1, UNDEFINED, availableApis.usct.z);
            //   coreConsentApiUsnat.section = 'usct';
            // } else if (coreCfg.region === 'utah' || coreCfg.region === 'ut') {
            //   apiSetConsentRegion(UNDEFINED, UNDEFINED, 1, UNDEFINED, availableApis.usut.z);
            //   coreConsentApiUsnat.section = 'usut';
            // } else if (coreCfg.region === 'virginia' || coreCfg.region === 'va') {
            //   apiSetConsentRegion(UNDEFINED, UNDEFINED, 1, UNDEFINED, availableApis.usva.z);
            //   coreConsentApiUsnat.section = 'usva';
            // } else {
            //   apiSetConsentRegion(UNDEFINED, UNDEFINED, 1, UNDEFINED, availableApis.usnat.z);
            // }
            // Refer to SN-12956 for 'ch' addition.
          } else if ('at be bg hr cy cz dk ee fi fr de gr hu is ie it lv li lt lu mt nl no pl pt ro sk si es se gb ch'.indexOf(coreCfg.country) !== -1) {
            apiSetConsentRegion(UNDEFINED, UNDEFINED, 1, UNDEFINED, availableApis.tcfeuv2.z);
            // } else if(coreCfg.country === 'ca') {
            //   apiSetConsentRegion(UNDEFINED, UNDEFINED, 1, UNDEFINED, availableApis.tcfcav1.z);
          } else {
            if (!coreCfg.country) {
              coreAnalyticsSend('ErrorGeotargeting');
              coreLogError('Geotargeting failed');
            }
            apiSetConsentRegion(UNDEFINED, UNDEFINED, 1, UNDEFINED, 0);
          }
          apiStart();
        });
        return;
      }

      // Prevent more than one start call
      if (started) return;
      started = true;

      if (coreConsent.region == 0) {
        // If there is no consent region, trigger final events
        coreEvent.dispatchCustomEventConsent(3, 3);
      } else if (gppPingReturn.currentAPI) {
        // load full business script for the configured consent region
        const script = doc.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.charset = 'utf-8';
        script.src = coreObj.cfg.apiBaseUrl + '/adconsent' + availableApis[gppPingReturn.currentAPI].f + '.js';
        doc.head.appendChild(script);
      }
    }

    // Reprocess all command in all stub queues
    function reprocessStubQueues() {
      const processApiQueue = api => {
        const cmpApiObj = win[api];
        const cmdQueue = cmpApiObj.queue;

        if (cmdQueue) {
          cmpApiObj.queue = [];
          for (let i = 0; i < cmdQueue.length; i++) {
            const cmd = cmdQueue[i];
            cmpApiObj(cmd.command, cmd.version, cmd.callback, cmd.parameter);
          }
        }
      };

      if (coreConsent.region !== availableApis.tcfeuv2.z) {
        coreGDPR.processListeners(getTCDataForStub);
      }

      if (coreConsent.region !== availableApis.uspv1.z) {
        processApiQueue(uspapi);
      }

      if (coreConsent.region === 0) {
        //if no region, reprocess the stub queues.
        //there are commands the stub can execute after no consent region is set ie: "isConsentWallUser"
        processApiQueue(globalapi);
      }
    }

    // Set the consent region of the cmp
    function apiSetConsentRegion(_cmpApiObj, _command, version, _callback, region) {
      if (!version) {
        coreLogWarn('setConsentRegion is deprecated and should be only used in debug mode.');
      }
      if (coreConsent.region !== UNDEFINED) {
        // Consent region is already defined, skip this call
        return;
      }
      if (!coreConsentRegions[region]) {
        coreLogError('Incorrect consent region ' + region);
        return;
      }

      // Set the consent region in the configuration
      for (let api in availableApis) {
        const consentApi = coreConsentApi[api];
        consentApi.applies = consentApi.region == region;
        if (consentApi.applies) {
          gppPingReturn.currentAPI = api;
        } else {
          // This consent region does not apply, so status is finally loaded
          consentApi.loaded = true;
          consentApi.status = loaded;
        }
      }
      coreConsent.region = region;
      gppPingReturn.applicableSections = [gppPingReturn.currentAPI ? availableApis[gppPingReturn.currentAPI].sid : -1];
      coreLogInfo('Configured consent region ' + coreConsentRegions[region]);

      // Reprocess command queues to execute privacy functions like getTCData, addEventListener, getUSPData
      reprocessStubQueues();
    }

    // Set AdConsent storage type
    function apiSetStorageType(_cmpApiObj, _command, _version, callback, parameter) {
      if (parameter !== 0 && parameter !== 1) {
        sendCallback(callback, { message: 'Invalid storage type: should be 0 (cookie) or 1 (local storage).' }, false);
      } else {
        if (parameter === 1 && !storage) {
          sendCallback(callback, { message: "Storage type 'localStorage' was selected, but local storage is not available. Reverting to cookie storage." }, false);
        } else {
          coreCfg.storage = parameter;
          sendCallback(callback, null, true);
        }
      }
    }

    // Set Publisher Country Code
    function apiSetPublisherCC(_cmpApiObj, _command, _version, callback, parameter) {
      if (!isString(parameter) || parameter.length != 2) {
        // wrong country code parameter
        sendCallback(callback, { message: 'Invalid publisher country code detected. Ignoring call.' }, false);
      } else {
        coreCfg.publisherCC = parameter.toUpperCase();
        sendCallback(callback, null, true);
      }
    }

    // Enable google analytics support
    function apiEnableGoogleAnalytics(_cmpApiObj, _command, _version, callback, enable) {
      coreAnalytics.enabled = enable === UNDEFINED ? true : !!enable;
      coreAnalytics.callback = callback;
    }

    // Is consent wall user
    function apiIsConsentWallUser(callback) {
      sendCallback(callback, { 'hasSubscription': false }, true);
    }

    coreObj.functions.isConsentWallUser = apiIsConsentWallUser;

    function apiStubIsConsentWallUser(cmpApiObj, command, version, callback) {
      //needed for adEngine to proceed with loading of the Ad Stack when a page has a consent wall configuration
      //found no region execute the command, otherwise put in qeue
      if (coreConsent.region === 0) {
        apiIsConsentWallUser(callback);
      } else {
        addToStubQueue(cmpApiObj, command, version, callback, null);
      }
    }

    function apiEnableConsentWall(_parameter, callback) {
      sendCallback(callback, { message: 'Consent Wall not available in this region', enabled: false }, true);
    }

    coreObj.functions.enableConsentWall = apiEnableConsentWall;

    function apiStubEnableConsentWall(cmpApiObj, command, version, callback, parameter) {
      if (coreConsent.region === 0) {
        apiEnableConsentWall(parameter, callback);
      } else {
        addToStubQueue(cmpApiObj, command, version, callback, parameter);
      }
    }

    // Is consent wall enabled
    function apiIsConsentWallEnabled(callback) {
      sendCallback(callback, { 'consentWallEnabled': false }, true);
    }

    coreObj.functions.isConsentWallEnabled = apiIsConsentWallEnabled;

    function apiStubIsConsentWallEnabled(cmpApiObj, command, version, callback) {
      // needed for the CP link to know if a page reload is necessary to load CP (when no consent region)
      if (coreConsent.region === 0) {
        apiIsConsentWallEnabled(callback);
      } else {
        addToStubQueue(cmpApiObj, command, version, callback, null);
      }
    }

    // ensure consent wall
    function apiEnsureConsentWall(parameter, _callback) {
      coreObj.utils.setStorageItem(pwUserStatusStorageName, 1, 31556926);
      if (isString(parameter)) {
        win.location.href = parameter;
      }
    }

    coreObj.functions.ensureConsentWall = apiEnsureConsentWall;

    function apiStubEnsureConsentWall(cmpApiObj, command, version, callback, parameter) {
      // when no consent region, we need to work it as in the other non-GDPR consent regions
      if (coreConsent.region === 0) {
        apiEnsureConsentWall(parameter, callback);
      } else {
        addToStubQueue(cmpApiObj, command, version, callback, parameter);
      }
    }

    const apiGetConsentValue = (_cmpApiObj, _command, _version, callback) => {
      const sendConsentValue = () => sendCallback(callback, { fullConsent: cmpHasFullConsent, region: gppPingReturn.currentAPI }, true);
      const onConsentAvailable = () => {
        doc.removeEventListener(cmpConsentAvailable, onConsentAvailable);
        sendConsentValue();
      };

      if (cmpHasFullConsent === undefined) {
        doc.addEventListener(cmpConsentAvailable, onConsentAvailable);
      } else {
        sendConsentValue();
      }
    };

    coreObj.functions.getConsent = apiGetConsentValue;

    const apiEnableInlineConsent = (_cmpApiObj, _command, _version, callback, parameter) => {
      const setOrAskForConsent = (vendorConsentElements, vendorId, vendorConsent, isCustomVendor) => {
        if (vendorConsent) {
          vendorConsentElements[vendorId].forEach(element => {
            if (element.processed) {
              // remove the inline consent code
            }
            element.elem.setAttribute('src', element.src);
          });
          delete vendorConsentElements[vendorId];
        } else {
          const labels = (parameter && parameter.labels) || {};
          vendorConsentElements[vendorId].forEach(element => {
            if (!element.processed) {
              element.processed = 1;
              // create the inline consent code
              if (element.type === 'IFRAME') {
                const doc = element.elem.contentDocument;
                doc.head.innerHTML += '<link rel="stylesheet" href="' + coreObj.cfg.apiBaseUrl + '/adconsent' + availableApis[gppPingReturn.currentAPI].f + '.css">';
                doc.head.innerHTML += '<style>.sn-inline-bg{text-align:center}.sn-inline-bg > p{height:calc(50% - 1.5em);align-content:end;text-align:center}.sn-b-def{width:50%;margin-left:10px}</style>';
                const cssStyles = document.getElementsByTagName('style');
                for (let i = 0; i < cssStyles.length; i++) {
                  doc.head.appendChild(cssStyles[i].cloneNode(true));
                }

                const div = doc.createElement('div');
                div.classList = 'snigel-cmp-framework sn-inline-bg';

                const paragraph = doc.createElement('p');
                paragraph.innerHTML = (labels.consentText || 'To see this content you need to give permission to vendor ') + (element.name ? element.name : vendorId);
                const bAllow = doc.createElement('div');
                bAllow.classList = 'sn-b-def sn-blue';
                bAllow.innerHTML = labels.allow || 'Allow';
                bAllow.addEventListener('click', () => coreGDPR('allowVendor', { id: vendorId, isCustom: isCustomVendor }));
                const bShow = doc.createElement('div');
                bShow.classList = 'sn-b-def';
                bShow.innerHTML = labels.showOptions || 'Show all options';
                bShow.addEventListener('click', () => coreObj('showGUI'));

                div.appendChild(paragraph);
                div.appendChild(bAllow);
                div.appendChild(bShow);
                doc.body.appendChild(div);
              }
            }
          });
        }
      };

      const inlineConsentElements = document.querySelectorAll('[data-cmp-vendor]');
      if (inlineConsentElements && inlineConsentElements.length) {
        const inlineElementsForVendor = { vendor: {}, customVendor: {} };

        inlineConsentElements.forEach(ice => {
          const cmpData = ice.dataset;
          const vendor = cmpData.cmpVendor.toUpperCase();
          const src = cmpData.cmpSrc || '';
          let inlineEntry;
          let vendorId;

          if (src.length > 2) {
            if (vendor.length && vendor.charAt(0) === 'C') {
              inlineEntry = inlineElementsForVendor.customVendor;
              vendorId = parseInt(vendor.substring(1));
            } else {
              inlineEntry = inlineElementsForVendor.vendor;
              vendorId = parseInt(vendor);
            }
            if (vendorId > 0) {
              inlineEntry[vendorId] ||= [];
              inlineEntry[vendorId].push({
                elem: ice,
                type: ice.tagName.toUpperCase(),
                processed: 0,
                name: cmpData.cmpVname || '',
                src: src
              });
            }
          }
        });
        coreGDPR('addEventListener', null, tcData => {
          if (tcData.gdprApplies === false || tcData.eventStatus === 'tcloaded' || tcData.eventStatus === 'useractioncomplete') {
            const vendorIds = Object.keys(inlineElementsForVendor.vendor);
            const cVendorIds = Object.keys(inlineElementsForVendor.customVendor);
            vendorIds.forEach(vendorId => setOrAskForConsent(inlineElementsForVendor.vendor, vendorId, tcData.gdprApplies === false || tcData.vendor.consents[vendorId]));
            if (cVendorIds.length) {
              if (tcData.gdprApplies === false) {
                cVendorIds.forEach(cVendorId => setOrAskForConsent(inlineElementsForVendor.customVendor, cVendorId, true, true));
              } else {
                coreGDPR('getCustomVendorsConsent', null, consents => {
                  cVendorIds.forEach(cVendorId => setOrAskForConsent(inlineElementsForVendor.customVendor, cVendorId, consents[cVendorId], true));
                });
              }
            }
          }
        });

        const numVendors = Object.keys(inlineElementsForVendor.vendor).length;
        const numCVendors = Object.keys(inlineElementsForVendor.customVendor).length;
        const plural = n => (n != 1 ? 's' : '');
        const message = 'Enabled inline consent for ' + numVendors + ' vendor' + plural(numVendors) + ' and ' + numCVendors + ' custom vendor' + plural(numCVendors) + '.';
        coreLogDebug(message);
        sendCallback(callback, { message: message }, true);
      }
    };

    // TCF API part
    handleAPI(tcfapi, {
      ping: function (_cmpApiObj, _command, _version, callback) {
        const pingReturn = {
          gdprApplies: coreConsentApiTcf.applies,
          cmpLoaded: coreConsentApiTcf.loaded,
          cmpStatus: coreConsentApiTcf.status,
          displayStatus: disabled,
          apiVersion: '2.2',
          cmpVersion: cmpVersion,
          cmpId: cmpId,
          gvlVersion: UNDEFINED,
          tcfPolicyVersion: 5
        };
        sendCallback(callback, pingReturn, true);
      },

      getTCData: coreGDPR.getTCData,
      addEventListener: function (_cmpApiObj, _command, version, callback, _parameter) {
        coreGDPR.addEventListener(version, callback, getTCDataForStub);
      },
      removeEventListener: coreGDPR.removeEventListener
    });

    // USP API part
    handleAPI(uspapi, {
      getUSPData: function (cmpApiObj, command, version, callback, parameter) {
        if (coreConsentApiUsp.applies === false) {
          if (checkVersion(coreConsentApiUsp, version, callback)) {
            const uspData = {
              version: availableApis.uspv1.v,
              uspString: availableApis.uspv1.v + '---'
            };
            sendCallback(callback, uspData, true);
            return uspData;
          }
        } else {
          addToStubQueue(cmpApiObj, command, version, callback, parameter);
        }
      }
    });

    function gppFibonacciEncArray(value, indexes) {
      indexes = indexes || [];

      if (!value) {
        return indexes;
      }
      let index = 0;
      while (value >= gppFibonacci[index]) {
        index++;
        if (index >= gppFibonacci.length) {
          gppFibonacci.push(gppFibonacci[index - 1] + gppFibonacci[index - 2]);
        }
      }
      indexes.push(index);
      return gppFibonacciEncArray(value - gppFibonacci[index - 1], indexes);
    }

    function gppProcessListeners(eventName, data) {
      for (let id in coreGPP.eventListeners) {
        if (id !== 'id') {
          sendCallback(coreGPP.eventListeners[id], gppReturnListenerData(eventName, id, data));
        }
      }
    }

    (function gppSetDisplayStatus() {
      const listenerFunc = function (event) {
        let processReady = true;
        const oldStatus = gppPingReturn.cmpStatus;
        gppPingReturn.cmpStatus = loaded;

        const oldDisplayStatus = gppPingReturn.cmpDisplayStatus;
        const eventType = event.type;
        if (eventType === cmpGUIShow) {
          gppPingReturn.cmpDisplayStatus = visible;
          gppPingReturn.signalStatus = 'not ready';
          gppProcessListeners('signalStatus', 'not ready');
          processReady = false;
        } else if (eventType === cmpGUISubmit) {
          gppPingReturn.cmpDisplayStatus = hidden;
          gppProcessListeners('sectionChange', gppPingReturn.currentAPI);
        }

        if (oldDisplayStatus !== gppPingReturn.cmpDisplayStatus) {
          gppProcessListeners('cmpDisplayStatus', gppPingReturn.cmpDisplayStatus);
        } else if (oldStatus !== loaded) {
          gppProcessListeners('cmpStatus', loaded);
        } else {
          // this means that there were no changes in the CMP status, so signalStatus ready was already dispatched before
          processReady = false;
        }
        if (processReady) {
          const gppData = gppGetGPPData();
          gppPingReturn.signalStatus = 'ready';
          gppPingReturn.sectionList = gppData.sectionList;
          gppPingReturn.gppString = gppData.gppString;
          gppPingReturn.parsedSections = gppData.parsedSections;
          gppProcessListeners('signalStatus', 'ready');
        }
      };
      doc.addEventListener(cmpGUIShow, listenerFunc);
      doc.addEventListener(cmpGUISubmit, listenerFunc);
      doc.addEventListener(cmpConsentAvailable, listenerFunc);
    })();

    function gppPing(_cmpApiObj, _command, callback) {
      const gppPingData = JSON.parse(JSON.stringify(gppPingReturn));
      sendCallback(callback, gppPingData, true);
      return gppPingData;
    }

    function gppSendError(command, callback, msg) {
      const errMsg = gppapi + "() API call '" + command + "': " + msg;
      coreLogError(errMsg);
      sendCallback(callback, { message: errMsg }, false);
      return null;
    }

    function gppGetSection(_cmpApiObj, command, callback, parameter) {
      const section = isString(parameter) && availableApis[parameter];
      if (section) {
        const apiStruct = getApiStruct(section.api);
        return apiStruct.internal ? apiStruct.api(section.s, UNDEFINED, callback) : apiStruct.api(section.s, section.v, callback);
      } else {
        return gppSendError(command, callback, "Unknown section '" + parameter + "'.");
      }
    }

    function gppReturnListenerData(eventName, listenerId, data) {
      return {
        eventName: eventName,
        listenerId: Number.parseInt(listenerId),
        data: data,
        pingData: gppPing()
      };
    }

    function gppGetGPPData(forInterface) {
      const gppData = {
        sectionId: 3,
        gppVersion: 1,
        parsedSections: {}
      };

      if (forInterface) {
        gppData.pingData = gppPing();
        gppData.applicableSections = [gppPingReturn.currentAPI ? availableApis[gppPingReturn.currentAPI].sid : -1];
      }

      const intToBits = function (number, nBitsToAlignTo, alignRight) {
        let bitString = isString(number) ? number : Number(number).toString(2);
        if (nBitsToAlignTo) {
          const padding = (nBitsToAlignTo - (bitString.length % nBitsToAlignTo)) % nBitsToAlignTo;
          for (let i = 0; i < padding; i++) {
            if (alignRight) {
              bitString += '0';
            } else {
              bitString = '0' + bitString;
            }
          }
        }
        return bitString;
      };

      let convertToBase64 = function (value) {
        const bitString = intToBits(value, 8, true);
        let base64Encoded = '';
        for (let byteOffset = 0; byteOffset < bitString.length; byteOffset += 8) {
          base64Encoded += String.fromCharCode(parseInt(bitString.substr(byteOffset, 8), 2));
        }
        return win.btoa(base64Encoded).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
      };

      // get the remaining data
      const sectionsConfigured = {};
      const sectionKeys = [];
      for (let api in availableApis) {
        const apiDefs = availableApis[api];
        if (apiDefs && apiDefs.c) {
          const storageName = '_sn_' + apiDefs.c;
          const storageKeys = ((storage && storage.getItem(storageName)) || '').split('~');
          if (storageKeys && storageKeys.length === 2) {
            try {
              if (parseInt(storageKeys[0]) >= new Date().getTime()) {
                sectionsConfigured[apiDefs.sid] = storageKeys[1];
                sectionKeys.push(apiDefs.sid);
              } else {
                storage.removeItem(storageName);
              }
            } catch (error) {
              /* empty */
            }
          } else {
            const cookiePart = ('; ' + doc.cookie).split('; ' + apiDefs.c + '=');
            if (cookiePart.length >= 2) {
              sectionsConfigured[apiDefs.sid] = cookiePart[cookiePart.length - 1].split(';').shift();
              sectionKeys.push(apiDefs.sid);
            }
          }
          gppData.parsedSections[api] = gppGetSection(null, null, null, api);
        }
      }
      // create the gpp data header and contents
      let header = '';
      let contents = '';
      header += intToBits(gppData.sectionId, 6);
      header += intToBits(gppData.gppVersion, 6);
      header += intToBits(sectionKeys.length, 12);

      let lastKey = 0;
      gppData.sectionList = sectionKeys.sort(function (a, b) {
        return a - b;
      });
      gppData.sectionList.forEach(function (key) {
        header += intToBits(0, 1);
        const diffToLastKey = key - lastKey;
        header += coreGPP.fibonacciEncode(diffToLastKey);
        contents += '~' + sectionsConfigured[key];
        lastKey = key;
      });
      header = intToBits(header, 6, true);

      gppData.gppString = convertToBase64(header) + contents;
      return gppData;
    }

    // GPP API part
    // handleAPI(gppapi, {
    //   ping: gppPing,
    //   addEventListener: function(cmpApiObj, command, callback) {
    //     const success = isFunction(callback);
    //     if (success) {
    //       coreGPP.eventListeners[++coreGPP.eventListeners.id] = callback;
    //     }
    //     return gppReturnListenerData('listenerRegistered', success ? coreGPP.eventListeners.id : 0, success);
    //   },
    //   removeEventListener: function(cmpApiObj, command, callback, parameter) {
    //     const success = Number.isInteger(parameter) && !!coreGPP.eventListeners[parameter];
    //     if (success) {
    //       delete coreGPP.eventListeners[parameter];
    //     }
    //     return gppReturnListenerData('listenerRemoved', parameter, success);
    //   },
    //   hasSection: function(cmpApiObj, command, callback, parameter) {
    //     const section = gppGetSection(cmpApiObj, command, null, parameter);
    //     const hasSection = !!section;
    //     sendCallback(callback, hasSection, true);
    //     return hasSection;
    //   },
    //   getSection: gppGetSection,
    //   getField: function(cmpApiObj, command, callback, parameter) {
    //     const msgHeader = gppapi + "() API call '" + command + "': ";
    //     const msg;
    //     const parSplit = (isString(parameter) && parameter.split(".")) || [""];
    //     const section = gppGetSection(cmpApiObj, command, null, parSplit[0]);
    //     if (section) {
    //       if (parSplit.length > 1) {
    //         if (parSplit[1] && section.hasOwnProperty(parSplit[1])) {
    //           const field = section[parSplit[1]];
    //           sendCallback(callback, field, true);
    //           return field;
    //         } else {
    //           return gppSendError(command, callback, "Section '" + parSplit[0] + "' has no field '" + parSplit[1] + "'.");
    //         }
    //       } else {
    //         return gppSendError(command, callback, "No field defined for section '" + parSplit[0] + "'.");
    //       }
    //     } else {
    //       return gppSendError(command, callback, "Unknown section '" + parSplit[0] + "'.");
    //     }
    //   },
    //   getGPPData: function(cmpApiObj, command, callback) {
    //     const gppData = gppGetGPPData(true);
    //     sendCallback(callback, gppData, true);
    //     return gppData;
    //   }
    // });

    // TCFCA API part
    handleAPI(availableApis.tcfcav1.api, {});

    // USNAT API part
    handleAPI(availableApis.usnat.api, {});

    // Common/Legacy API part
    handleAPI(globalapi, {
      start: apiStart,
      setStorageType: apiSetStorageType,
      setPublisherCC: apiSetPublisherCC,
      setConsentRegion: apiSetConsentRegion,
      enableGoogleAnalytics: apiEnableGoogleAnalytics,
      enableInlineConsent: apiEnableInlineConsent,
      isConsentWallUser: apiStubIsConsentWallUser,
      isConsentWallEnabled: apiStubIsConsentWallEnabled,
      ensureConsentWall: apiStubEnsureConsentWall,
      enableConsentWall: apiStubEnableConsentWall,
      getConsent: apiGetConsentValue
    });

    const adConsentReadyEvent = coreName + 'Ready';
    win.dispatchEvent(new CustomEvent(adConsentReadyEvent));
    win.dataLayer ||= [];
    win.dataLayer.push({ 'event': adConsentReadyEvent });
  } catch (e) {
    sendToArgus(e);
    if (snigelConfig && snigelConfig.passThroughException) {
      throw e;
    } else {
      console.error(e);
    }
  }
})(window, document, 'adconsent', '__gpp', '__tcfapi', '__tcfca', '__uspapi');
